var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Limit the amount of links shown", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "By default Vuesax will show a maximum of 9 links. This default behavior can be changed by setting the "
        ),
        _c("code", [_vm._v("max")]),
        _vm._v(" directive to a different integer value"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "my-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("The amount given to the "),
            _c("code", [_vm._v("max")]),
            _vm._v(
              ' directive should take into account the first and last pages plus the ellipses "..."'
            ),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        { attrs: { icon: "warning", active: "true", color: "warning" } },
        [
          _c("span", [
            _vm._v("Setting the "),
            _c("code", [_vm._v("max")]),
            _vm._v(" directive to a value lesser then "),
            _c("code", [_vm._v("5")]),
            _vm._v(" when the "),
            _c("code", [_vm._v("total")]),
            _vm._v(
              " is greater then that will result in the pagination only showing the previous/next buttons as well as the first and last links"
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c("vs-pagination", {
            attrs: { total: 30, max: 13 },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-pagination :total="30" v-model="currentx" :max="13"></vs-pagination>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    currentx: 17\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }