var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "GoTo", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("The "),
        _c("code", [_vm._v("goto")]),
        _vm._v(
          " directive will allow users to directly jump to a desired page"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("If the value given is lower than "),
            _c("strong", [_vm._v("1")]),
            _vm._v(" it will jump to page "),
            _c("strong", [_vm._v("1")]),
            _vm._v(
              ". However if the value is greater than total it will jump to the last page"
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-pagination", {
            attrs: { total: 20, goto: "" },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-pagination :total="20" v-model="currentx" goto></vs-pagination>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    currentx: 14\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }