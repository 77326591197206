var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "pagination-demo" } },
    [
      _c("pagination-default"),
      _c("pagination-icon"),
      _c("pagination-links-limit"),
      _c("pagination-go-to"),
      _c("pagination-color"),
      _c("pagination-programmatic-inc-dec"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }