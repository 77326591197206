var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "The pagination component consists of several elements, all of which are optional. So with no extra class, your pagination links will look as follow"
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c("vs-pagination", {
            attrs: { total: 40 },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-pagination :total="40" v-model="currentx"></vs-pagination>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    currentx: 14\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }