var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Programmatic Inc/Dec", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can also update page number from outside of pagination component."
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c("p", { staticClass: "mb-3" }, [
            _vm._v("Current Page: " + _vm._s(_vm.currentx)),
          ]),
          _c(
            "vs-button",
            {
              on: {
                click: function ($event) {
                  _vm.currentx++
                },
              },
            },
            [_vm._v("Increment")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-4 mb-4",
              on: {
                click: function ($event) {
                  _vm.currentx--
                },
              },
            },
            [_vm._v("Decrement")]
          ),
          _c("vs-pagination", {
            attrs: { total: 20 },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-pagination :total="20" v-model="currentx"></vs-pagination>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    currentx: 5\n  })\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }