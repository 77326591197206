var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To customize the previous and next page icons change the value of the prev-icon and prev-icon directives. By default, their values are respectively arrow_back and arrow_forward"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c("vs-pagination", {
            attrs: {
              total: 100,
              "prev-icon": _vm.$vs.rtl ? "arrow_forward" : "arrow_back",
              "next-icon": _vm.$vs.rtl ? "arrow_back" : "arrow_forward",
            },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <vs-pagination\n    :total=\"100\"\n    v-model=\"currentx\"\n    :prev-icon=\"$vs.rtl ? 'arrow_forward' : 'arrow_back'\"\n    :next-icon=\"$vs.rtl ? 'arrow_back' : 'arrow_forward'\" />\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    currentx: 64\n  })\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }