var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the color of the component with the property color, you can use the Main colors or RGB and HEX colors"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex justify-center items-center mt-5" },
        [
          _c("vs-pagination", {
            attrs: { color: _vm.colorx, total: 20 },
            model: {
              value: _vm.currentx,
              callback: function ($$v) {
                _vm.currentx = $$v
              },
              expression: "currentx",
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            staticClass: "input-color ml-4",
            attrs: { type: "color" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="flex justify-center items-center">\n      <vs-pagination :color="colorx" :total="20" v-model="currentx"></vs-pagination>\n      <input class="input-color ml-4" v-model="colorx" type="color">\n  </div>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    colorx: \'#f91f43\',\n    currentx: 7\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }